import {
    getServerRequest,
    postServerRequest,
    putServerRequest,
    patchServerRequest,
    deleteServerRequest
} from '../../helpers/serverRequest';
import { store } from '../../store/store';

const httpURL = store.getters.httpURL;

export async function getSesionesService() {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/sesiones`;
    return await getServerRequest(url, config);
}

export async function getSesionService(idSesion) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/sesiones/admin/${idSesion}`;
    return await getServerRequest(url, config);
}

export async function agregarSesionService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/sesiones/sesion`;
    return await putServerRequest(url, data, config);
}

export async function abrirCerrarSesionService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/sesiones/abrirCerrar/${data.idSesion}`;
    return await patchServerRequest(url, data, config);
}

export async function buscarUsuariosService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/usuarios/busqueda`;
    return await postServerRequest(url, data, config);
}

export async function agregarAdminSesionService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/sesiones/administrador`;
    return await putServerRequest(url, data, config);
}

export async function modificarDependenciaAdminSesionService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/sesiones/administrador/asistencia/dependencia`;
    return await putServerRequest(url, data, config);
}

export async function modificarPermisosAdminSesionService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/sesiones/administrador/asistencia/permisos`;
    return await putServerRequest(url, data, config);
}

export async function eliminarAdminSesionService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token }, data };
    const url = `${httpURL}/sesiones/administrador`;
    return await deleteServerRequest(url, config);
}

export async function asignarEnlaceVotacionService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/votacion/enlace/${data.idSesion}`;
    return await putServerRequest(url, data, config);
}

export async function eliminarEnlaceVotacionService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token }, data };
    const url = `${httpURL}/votacion/enlace/${data.idSesion}`;
    return await deleteServerRequest(url, config);
}

export async function activarDesactivarEnviarCorreoService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/sesiones/activarDesactivarEnviarCorreo/${data.idSesion}`;
    return await patchServerRequest(url, data, config);
}

export async function getEnviarCorreoService(idSesion) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/sesiones/correo/${idSesion}`;
    return await getServerRequest(url, config);
}

export async function subirCSV(file) {
    const token = store.getters.sessionToken;
    const config = { headers: { token, "Content-Type": "multipart/form-data" } };
    const url = `${httpURL}/votacion/enlaces/csv`;
    return await putServerRequest(url, file, config);
}

// Asistencias
export async function getAsistentesService(idSesion) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/asistencias/sesion/${idSesion}`;
    return await getServerRequest(url, config);
}

export async function agregarAsistenciaService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/asistencias/sesion/${data.idSesion}`;
    return await putServerRequest(url, data, config);
}


export async function agregarAsistenciaQRService(idSesion, data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/asistencias/sesion/qr/${idSesion}`;
    return await putServerRequest(url, data, config);
}

export async function exportarExcel(idSesion, data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token }, responseType: 'blob' };
    const url = `${httpURL}/asistencias/sesion/exportar/${idSesion}`;
    return await putServerRequest(url, data, config);
}

export async function eliminarAsistenteSesion(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token }, data };
    const url = `${httpURL}/asistencias/sesion/eliminar`;
    return await deleteServerRequest(url, config);
}

export async function enviarCorreoVotacion(data){
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/asistencias/sesion/enviarCorreo/votacion/${data.idSesion}`;
    return await putServerRequest(url, data, config);
}

export async function modificarSesionService(id, data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/sesiones/sesion/${id}`;
    return await putServerRequest(url, data, config);
}